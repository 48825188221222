// When adding a new env var, don't forget to add its type definition to env.d.ts also!
const {
  NODE_ENV,
  VITE_BUILDNUMBER,
  VITE_MAINTENANCE_MODE,
  VITE_VUE_ENV,
  VITE_TAG_CO_ENV,
  VITE_URL_ENV_EXC,
  VITE_MAX_LOGIN_ATTEMPTS,

  VITE_API_V3_URL,
  VITE_API_V4_URL,
  VITE_CLIENT_V1_URL,
  VITE_CLICK_URL,
  VITE_REDUP_URL,
  VITE_SALESFORCE_URL,
  VITE_MULESOFT_URL,
  VITE_MULESOFT_CLIENT_ID,
  VITE_MULESOFT_CLIENT_SECRET,

  VITE_POWERBI_EMBED_URL,
  VITE_POWERBI_REPORT_ID,

  VITE_ENABLED_FEATURES,
  VITE_DISABLED_FEATURES,

  VITE_GTM_ID,
  VITE_GOOGLE_MAPS_API_KEY,
  VITE_RECAPTCHA_KEY,
  VITE_SENTRY_DSN,

  VITE_CONTENTFUL_SPACE_ID,
  VITE_CONTENTFUL_DELIVERY_API_KEY,
  VITE_CONTENTFUL_ENVIRONMENT,
  VITE_CONTENTFUL_PREVIEW_API_KEY,
} = import.meta.env

export { NODE_ENV }
export const BUILDNUMBER = VITE_BUILDNUMBER
export const MODE_MAINTENANCE = VITE_MAINTENANCE_MODE
export const vueEnv = VITE_VUE_ENV
export const urlEnvExc = VITE_URL_ENV_EXC
export const tagCoEnv = VITE_TAG_CO_ENV
export const maxLoginAttempts = VITE_MAX_LOGIN_ATTEMPTS ? parseInt(VITE_MAX_LOGIN_ATTEMPTS, 10) : undefined

export const clickUrl = VITE_CLICK_URL
export const clientApiUrl = VITE_API_V3_URL
export const clientApiV4 = VITE_API_V4_URL
export const clientUrlV1 = VITE_CLIENT_V1_URL
export const clientUrlRedUp = VITE_REDUP_URL
export const urlSalesforce = VITE_SALESFORCE_URL
export const leadApiUrl = VITE_MULESOFT_URL
export const leadApiClientId = VITE_MULESOFT_CLIENT_ID
export const leadApiClientSecret = VITE_MULESOFT_CLIENT_SECRET

export const powerBiEmbedURL = VITE_POWERBI_EMBED_URL
export const powerBiReportId = VITE_POWERBI_REPORT_ID

export const ENABLED_FEATURES = VITE_ENABLED_FEATURES
export const DISABLED_FEATURES = VITE_DISABLED_FEATURES

export const gtmId = VITE_GTM_ID
export const googleMapsApiKey = VITE_GOOGLE_MAPS_API_KEY
export const RECAPTCHA_KEY = VITE_RECAPTCHA_KEY
export const SENTRY_DSN = VITE_SENTRY_DSN

export const contentfulSpaceId = VITE_CONTENTFUL_SPACE_ID
export const contentfulDeliveryApiKey = VITE_CONTENTFUL_DELIVERY_API_KEY
export const contentfulEnvironment = VITE_CONTENTFUL_ENVIRONMENT
export const contentfulPreviewApiKey = VITE_CONTENTFUL_PREVIEW_API_KEY
